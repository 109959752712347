import List from 'list.js'
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="list"
export default class extends Controller {

  static targets = ['availableFeaturedListings', 'item']

  availableFeaturedListingsTargetConnected(element) {
    var options = {
      valueNames: [ 'listing-title', 'listing-location' ],
      page: 10,
      visibleItems: ".summary",
      pagination: {
        // outerWindow: 1,
        // innerWindow: 1,
        includeDirectionLinks: true,
        leftDirectionText: '← Previous',
        rightDirectionText: 'Next →',
        item: "<li><span class='page link'></span></li>"
      }
    };

    var listObj = new List('listId', options);
  }

  toggleMoreListItems(event) {
    const buttonText = event.target.innerText == 'View More' ? 'View Less' : 'View More';
    const items = this.itemTargets;
    event.target.innerText = buttonText;

    items.forEach( (item) => {
      if ( item.classList.contains('li-to-hide') ) {
        item.classList.toggle('hidden');
      }
    });
  }
}
